<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import Datatable from "@/router/layouts/table-datatable.vue";

export default {
    page: {
        title: "Buat Surat Tugas Asesor banding",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        // Datatable,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Nomor Asesmen", name: "-" },
            { width: "auto", label: "Nama Skema Sertifikasi", name: "-" },
            { width: "200px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            title: "Buat Surat Tugas Asesor Banding",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },    
                {
                    text: "Asesor Banding",
                    active: "/pasca-asesmen/daftar-banding-admin",	
                },
                {
                    text: "Surat Tugas Asesor Banding",
                    active: true,
                },
                
            ],
            // Catch Error Axios
            axiosCatchError: null,
            id_rapat_banding: this.$route.params.id,
            // variabel referensi
            optionsMenuParent: [],
            tanggal_surat_tugas : new Date().toISOString().slice(0, 10),
            perihal_surat_tugas : "Surat Tugas Jadwal Rapat Banding",
            nomor_surat_tugas : null,
            urutan_nomor: null,
            tuk: null,
            tempat_pelaksanaan : null,
            //modal
            showModalAsesor: false,
              // variable Page Table
            columns: columns,
            sortKey: "", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",
            optionsAsesmen: [],
            optionsAsesi: [],
            data_asesmen:[
                {
                    'id_asesmen': null,
                    'no_asesmen': '',
                    'nomor_surat_banding': '',
                    'metode_id' : null,
                    'metode_nama' : null,
                    'skema_id' : null,
                    'nama_skema_sertifikasi': '',
                }
            ],
            data_asesor_banding: [], 
            preview_surat_tugas: null,
            next_status: null,
            data_rapat_banding: null,
            optionsAsesor: [],
            disclaimer_surat_tugas: false,
            tanggal_investigasi_dari: null,
            tanggal_investigasi_sampai: null,
            hak_akses: false,
        };
    },
    mounted() {
        this.getDetailRapatBanding();
        this.getAsesiRapatPLeno();
        this.getAsesor();
    },
    methods: {
        addAsesorBanding() {
            this.data_asesor_banding.push({
                'asesi': null,
                'asesor': null,
            });
        },
        removeAsesorBanding(index) {
            this.data_asesor_banding.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalAsesor = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalAsesor = true;
            });
        },
        convertToRoman(num) {
            var roman = { M: 1000, CM: 900, D: 500, CD: 400,C: 100,XC: 90, L: 50,XL: 40, X: 10, IX: 9,  V: 5, IV: 4, I: 1 };
            var str = '';
            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        getAsesemenFinal(){
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesmen-final",
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.optionsAsesmen = response.data.data.referensi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        validasiGenerateSurat(){
            if (this.tanggal_surat_tugas == null || this.tanggal_surat_tugas == '') {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Tanggal Surat Tugas Harus Diisi",
                });
                return false;
            }
            if (this.tempat_pelaksanaan == null || this.tempat_pelaksanaan == '') {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Tempat Pelaksanaan Harus Diisi",
                });
                return false;
            }
            else{
                return this.StoreAsesor();
            }
        },
        simpanSurat(){
            let self = this;
            self.showModalAsesor = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/ubah-status",
                data: {
                    id: self.id_rapat_banding,
                    next_status : self.next_status.id,
                    
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list rapat banding",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            self.$router.push({ name: "daftar-banding-admin" });
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        getDetailRapatBanding() {            
            let self = this;
            self.loadingTable = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/detail",
                params: {
                    id: self.id_rapat_banding,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.data_rapat_banding = data_edit;
                        self.next_status = data_edit.next_status;
                        self.data_asesmen = data_edit.rapat_banding_detail;
                        self.hak_akses = response_data.hak_akses;
                        // self.table_data = data_edit.rapat_banding;
                        // var currentDate = new Date();
                        self.loadingTable = false;
                        // self.getNomorVerifikasi();
                        console.log(self.data_asesmen);
                        
                   
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getAsesiRapatPLeno(){
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/get-asesi-rapat-banding",
                params: {
                    id: self.id_rapat_banding,
                },
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;

                if (response_data.meta.code == 200) {
                    self.optionsAsesi = response_data_fix.data.asesi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        StoreAsesor(){
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/store-asesor",
                data: {
                    rapat_banding_id: self.id_rapat_banding,
                    meta_asesor : self.data_asesor_banding,
                    tanggal_investigasi_dari : self.tanggal_investigasi_dari,
                    tanggal_investigasi_sampai : self.tanggal_investigasi_sampai,
                    tempat_pelaksanaan : self.tempat_pelaksanaan,
                    qr : true,
                },
                
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }
            axios(config)
                .then(function (response) {
                        Swal.close();
                        let res = response.data.data;
                        self.preview_surat_tugas = res.pdf;
                        self.next_status = res.data_rapat_banding.next_status;
                    })
        },
        getAsesor(){
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesor-banding",
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.optionsAsesor = response.data.data.referensi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        changeSelectAsesor(index){
            console.log(this.data_asesor_banding[index].asesor)
        
        },
        ModalTambahAsesor(){
            let self = this;
            self.showModalAsesor = true;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="ModalTambahAsesor">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Formulir Surat Tugas Asesor Banding</h3>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <table class="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <td style="width: 350px"><strong>Nomor Jadwal Rapat Banding </strong></td>
                                                <td> {{ data_rapat_banding?.rapat_banding_nomor }}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Tanggal Rapat Banding</strong></td>
                                                <td> {{ data_rapat_banding?.rapat_banding_tanggal ? data_rapat_banding.rapat_banding_tanggal : 'Data belum tersedia' }}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Perihal</strong></td>
                                                <td> {{ data_rapat_banding?.perihal }} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Tempat Pelaksanan Rapat Banding</strong></td>
                                                <td> {{ data_rapat_banding?.tuk_nama  ? data_rapat_banding.tuk_nama : 'Data belum tersedia' }} </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Status</strong></td>
                                                <td> {{ data_rapat_banding?.status_nama }} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 mt-4">
                                    <label for="example-text-input" class="col-form-label">List Asesmen</label>
                                    <table class="table mb-0 table-bordered table-condensed table-hover mt-4 mb-4">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>No Asesmen</th>
                                                <th class="text-center" style="width: auto">Nama Skema</th>
                                                <th class="" style="width: 40%">Asesi</th>
                                            </tr>
                                        </thead>
                                            <tbody class="text-center">
                                            <tr v-if="data_asesmen.length == 0">
                                                <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                            </tr>
                                            <tr v-else v-for="(data, index) in data_asesmen" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td> {{ data?.asesmen_nomor }} </td> 
                                                <td> {{ data?.skema_nama }}</td>
                                                <td>
                                                    <div v-if="data?.rapat_banding_asesi?.length == 0">
                                                        <span class="badge badge-danger">Data Asesi Tidak Tersedia</span>
                                                    </div>
                                                    <ul v-else>
                                                        <li v-for="(asesi_banding, index) in data?.rapat_banding_asesi" :key="index">
                                                            {{ asesi_banding?.asesi?.npk }} -  {{ asesi_banding?.asesi_nama }}
                                                        </li>
                                                    </ul>
                                                </td> 
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-md-6 mt-3" v-if="hak_akses">
                            <div class="text-end">
                                <b-button type="submit" variant="primary" class="m-1">
                                    <i class="fas fa-user"></i> Pilih Asesor Banding
                                </b-button>
                            </div>
                        </div>    
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="showModalAsesor" title="Surat Tugas Rapat Banding" title-class="text-black font-18" body-class="p-3"
        size="lg" hide-footer>
        <div class="row">
            <form @submit.prevent="simpanSurat">
                <!-- tangal pelaksaan -->
                <div class="row">
                    <div class="form-group col-12">
                        <label for="tipe_menu">Tanggal Investigasi Dari<span class="required-field"></span></label>
                        <input type="date" v-model="tanggal_investigasi_dari" class="form-control">
                    </div>
                    <div class="form-group col-12">
                        <label for="tipe_menu">Tanggal Investigasi Sampai<span class="required-field"></span></label>
                        <input type="date" v-model="tanggal_investigasi_sampai" class="form-control">
                    </div>
                    <div class="form-group col-12">
                        <label for="tipe_menu">Tempat Pelaksanaan Rapat Banding <span class="required-field"></span></label>
                        <input type="text" v-model="tempat_pelaksanaan" class="form-control">
                    </div>
                </div>
                <div class="col-12">
                    <label for="example-text-input" class="col-form-label mt-4">List Asesor Banding</label>
                    <table class="table mb-0 table-bordered table-condensed table-hover mb-4">
                        <thead class="bg-dark text-center text-white">
                            <tr>
                                <th style="width: 50px">No</th>
                                <th class="text-center" style="width: 40%">Asesi</th>
                                <th class="text-center" style="width: 45%">Tim Asesor Banding</th>
                                <th class="text-center" style="width: 60px">
                                    <div class="btn btn-success btn-sm" v-on:click="addAsesorBanding"><i class="bx bx-plus"></i></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="data_asesor_banding.length == 0">
                                <td class="text-center" colspan="4">Data Tidak Tersedia</td>
                            </tr>
                            <tr v-else v-for="(data, index) in data_asesor_banding" :key="index">
                                <td>{{ index + 1 }}</td>
                                 <td>
                                    <v-select :options="optionsAsesi" label="asesi_nama" v-model="data.asesi" placeholder="Pilih Asesi">
                                        <template v-slot:option="option">{{ option.asesi?.npk }} - {{ option.asesi_nama }} ({{ option.asesmen_nomor }})</template>
                                        <template v-slot:selected-option="option"> {{ option.asesi?.npk }} - {{ option.asesi_nama }} ({{ option.asesmen_nomor }}) </template>
                                    </v-select>
                                </td>
                                <td>
                                    <v-select :options="optionsAsesor" label="nama_asesor" v-model="data.asesor" placeholder="Pilih Asesor Banding" @select="changeSelectAsesor(index)">
                                        <template v-slot:option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_banding }} Frekuensi Asesor Banding</template>
                                        <template v-slot:selected-option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_banding }} Frekuensi Asesor Banding</template>
                                    </v-select>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-danger btn-sm mx-2" v-on:click="removeAsesorBanding(index)">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 col-md-6">
                    <div class="text-end">
                        <b-button @click="validasiGenerateSurat" variant="primary" class="m-1">
                            <i class="fas fa-save"></i> Generate Surat Tugas Rapat Banding
                        </b-button>
                    </div>
                </div>
                <div class="row" v-if="preview_surat_tugas">
                    <h3>Preview Surat Tugas Rapat banding</h3>
                    <div class="row p-4">
                        <div class="card" style="outline-style: outset">
                            <div class="card-body">
                                <iframe v-bind:src="preview_surat_tugas" width="100%" height="400"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3 " v-if="preview_surat_tugas">
                    <div class="form-group"><input type="checkbox" v-model="disclaimer_surat_tugas" /> Apakah Anda yakin? asesor yang dipilih akan segera mendapat notifikasi rapat banding</div>
                    <div class="form-group text-end">
                        <button class="btn btn-primary" type="submit"  v-if="disclaimer_surat_tugas"><i class="fas fa-save"></i> Simpan</button>
                        <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
                    </div>                    
                </div>
            </form>
        </div>
    </b-modal>
</template>
